<template>
  <div>
    <div
      v-if="quotedMessage"
      class="flex cursor-pointer justify-between rounded-tl rounded-tr bg-gray-700/50 px-4 py-2 text-xs text-gray-200"
    >
      <div class="flex w-full flex-1 items-center space-x-1 text-xs font-semibold leading-4">
        <span class="text-gray-400">Replying to </span>
        <span class="whitespace-nowrap text-gray-100">{{ quotedMessage.user.name }}</span>
      </div>
      <div @click="$emit('remove-quoted-message')">
        <XMarkIcon class="h-4 w-4 text-gray-100 hover:text-white"></XMarkIcon>
      </div>
    </div>
    <form class="flex flex-col">
      <div class="rounded-t bg-gray-700/50" v-if="uploadFiles.length > 0">
        <UploadsPreview
          :files="uploadFiles"
          :external="external"
          @remove-file="uploadFiles = uploadFiles.filter(f => f.name !== $event)"
        />
      </div>
      <div class="flex items-start rounded bg-gray-800 px-2 py-1">
        <div class="flex items-center space-x-1 py-1">
          <div
            class="flex items-center justify-center rounded bg-gray-600 py-3 hover:bg-gray-500"
            :class="external ? 'h-3 w-7' : 'h-5 w-9'"
          >
            <PaperClipIcon
              class="cursor-pointer text-gray-100"
              :class="external ? 'h-3.5 w-3.5' : 'h-4 w-4'"
              @click="triggerFileSelector()"
            />
          </div>
          <div
            tabindex="0"
            class="flex cursor-pointer items-center justify-center rounded bg-gray-600 py-3 hover:bg-gray-500"
            :id="`gif-button-${channelId}`"
            :class="external ? 'h-3 w-8' : 'h-5 w-10'"
            @click="$emit('show:gifs')"
          >
            <span class="font-bold leading-5" :class="external ? 'text-xs' : 'text-sm'">GIF</span>
          </div>
        </div>
        <input ref="file" type="file" style="display: none" @change="onFileChange" multiple />
        <div class="flex w-full items-center px-2">
          <textarea
            :id="composerId"
            v-model="message"
            :placeholder="'Send Message'"
            ref="resizeText"
            :rows="1"
            :class="[
              'overflow-y-auto border-none py-2 text-sm focus:outline-none',
              external ? 'max-h-[90px]' : 'max-h-[150px]'
            ]"
            @input="resizeComposer()"
            @keydown.enter.exact.prevent="sendMessage($event)"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount, watch } from 'vue';
import UploadsPreview from './ChatUploadsPreview.vue';
import useEmitter from '@/composeables/emitter';
import { PaperClipIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { flashMessage } from '@/composeables/helpers';

const $emitter = useEmitter();
const $emit = defineEmits(['show:gifs', 'message:send', 'scroll-to-bottom']);

const props = defineProps({
  quotedMessage: Object,
  external: Boolean,
  channelId: String
});

// FILES
const uploadFiles = ref([]);
const file = ref(null);
function onFileChange(e) {
  const files = e.target.files;
  if (uploadFiles.value.length + files.length > 10) {
    flashMessage({
      type: 'error',
      message: 'You can only upload 10 files at a time!'
    });
  } else if (Array.from(files).some(f => f.size > 8388608)) {
    flashMessage({
      type: 'error',
      message: 'Max file size is 8MB.'
    });
  } else {
    if (uploadFiles.value.length > 0) {
      Array.from(files).map(f => uploadFiles.value.push(f));
    } else {
      uploadFiles.value = Array.from(files);
    }
  }
  document.getElementById(composerId.value).focus();
}

function triggerFileSelector() {
  file.value.click();
}

onMounted(() => {
  $emitter.$on('files-added', files => {
    uploadFiles.value = [...uploadFiles.value, ...files];
  });
});

// MESSAGE
const resizeText = ref(null);
const message = ref('');
const composerId = ref(`composer_${Math.random()}`);
watch(
  () => props.quotedMessage,
  (newVal, oldVal) => {
    if (props.quotedMessage) {
      document.getElementById(composerId.value).focus();
    }
  }
);

onMounted(() => {
  const messageInput = document.getElementById(composerId.value);
  messageInput.focus();
  if (ResizeObserver) {
    new ResizeObserver(() => {
      if (document.activeElement === messageInput) {
        $emit('scroll-to-bottom');
      }
    }).observe(messageInput);
  }
});
function sendMessage(e) {
  e.preventDefault();
  if ((message.value && message.value.trim() != '') || uploadFiles.value.length > 0) {
    $emit('message:send', { text: message.value, files: uploadFiles.value });
    uploadFiles.value = [];
    resizeText.value.style.height = '36px';
    message.value = null;
  }
}
function resizeComposer() {
  let element = resizeText.value;
  element.style.height = '36px';
  element.style.height = element.scrollHeight + 'px';
}

// GIF SELECTION
function toggleGifSelection(e) {
  const messageInput = document.getElementById(composerId.value);
  var gifButton = document.getElementById(`gif-button-${props.channelId}`);
  if (e.key == 'Tab' && (gifButton === document.activeElement || messageInput == document.activeElement)) {
    $emit('show:gifs');
  }
}

onMounted(() => {
  window.addEventListener('keyup', toggleGifSelection);
});

onBeforeUnmount(() => {
  window.removeEventListener('keyup', toggleGifSelection);
  $emitter.$off('files-added');
});
</script>
